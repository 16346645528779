import React, { useState, useEffect, useCallback } from 'react';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import { withPrefix } from 'gatsby';
import Spin from '../spin/Spin';


const ShopMapGoogle = (({ loading, stores, activeItem, setActiveItem }) => {

  const [map, setMap] = useState(null);
  const [infoWindow, toogleInfoWindow] = useState(false)
  const onLoad = useCallback((map) => setMap(map), []);

  useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      stores.map(marker => {
        bounds.extend({
          lat: marker.lat,
          lng: marker.lng,
        });
      });
      map.fitBounds(bounds);
    }
  }, [map, stores]);

  const containerStyle = {
    width: '100%',
    height: '660px'
  };

  // const center = {
  //   lat: 52.22968,
  //   lng: 21.01223
  // };

  return (
    <div className="shopMapGoogle">
      {loading ? (
        <div className="shopMapGoogle__spin">
          <Spin />
        </div>
      ) : (
        <GoogleMap
          mapContainerStyle={containerStyle}
          // center={center}
          zoom={10}
          onLoad={onLoad}
        >
          {stores.map((marker, index) => {
            return (
              <Marker
                key={'marker_' + marker.id}
                icon={`${withPrefix('/images/map_icon.svg')}`}
                animation={activeItem === index ? '1' : '0'}
                zIndex={index === +activeItem ? 20 : 1}
                onClick={() => { setActiveItem(index); toogleInfoWindow(true) }}
                position={{
                  lat: +marker.lat,
                  lng: +marker.lng,
                }}
              >
                {infoWindow ? ((index === +activeItem) &&
                  <InfoWindow onCloseClick={() => { toogleInfoWindow(!infoWindow) }}>
                    <div className="shopMapGoogle__info">
                      <span className="shopMapGoogle__info__name"><span dangerouslySetInnerHTML={{ __html: marker.store }}></span></span>
                      <span className="shopMapGoogle__info__address">{marker.address} {marker.address2}<br />{marker.city} {marker.zip} {marker.country}</span>
                      {marker.url ? (
                        <span className="shopMapGoogle__info__link"><a href={marker.url} target="_blank">Link</a></span>
                      ) : ''}
                    </div>
                  </InfoWindow>) : (<></>)}
              </Marker>
            )
          })}
        </GoogleMap>
      )}
    </div>
  )
})
export default ShopMapGoogle
