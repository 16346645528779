import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { StandaloneSearchBox, } from '@react-google-maps/api';
import Geocode from "react-geocode";


const ShopMapFilters = ({ setStoresList, strings, storesCategory, lang, sendingPass, setsendingPass, loading, setLoading }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  Geocode.setApiKey(process.env.googleMapsApiKey);
  Geocode.setLanguage(lang);

  const radius = [
    {
      'name': '10km',
      'value': '10',
      'selected': false,
    },
    {
      'name': '25km',
      'value': '25',
      'selected': false,
    },
    {
      'name': '50km',
      'value': '50',
      'selected': true,
    },
    {
      'name': '100km',
      'value': '100',
      'selected': false,
    },
  ]




  const [lat, setLat] = useState(52.22968)
  const [lng, setLng] = useState(21.01223)
  const [searchBox, setSearchBox] = useState(null);
  const [storesCategoryList, setStoresCategoryList] = useState([]);
  const [radiusList, setRadiusList] = useState(radius);

  useEffect(() => {

    const tmp = [];
    storesCategory.nodes.forEach(element => {
      tmp.push({
        name: element.name,
        termTaxonomyId: element.termTaxonomyId,
        selected: false
      })
    });
    setStoresCategoryList(tmp);
    const formData = new FormData()
    // formData.append("location", values.location)
    formData.append("lang", lang)
    formData.append("lat", lat)
    formData.append("lng", lng)
    formData.append("action", 'store_search')
    formData.append("max_results", 25)
    formData.append("search_radius", 50)
    return fetch(process.env.wordPressUrl + "/wp-admin/admin-ajax.php", {
      method: "post",
      body: formData,
    }).then(response => response.json())
      .then((data) => {
        if (data) {
          Promise.all(
            data.map(async (maker) => {
              const address = 'ul. ' + maker.address + ' ' + maker.address2 + ', ' + maker.city + ' ' + maker.zip
              await Geocode.fromAddress(address)
                .then(
                  (response) => {
                    let position = response.results[0].geometry.location;
                    maker.lat = position.lat;
                    maker.lng = position.lng;
                  },
                  (error) => {
                    console.error(error);
                  }
                );
            })
          ).then(() => {
            setStoresList(data)
            setLoading(false)

          })
        }
        else {
          setStoresList([])
        }
      })
  }, []);

  const onSubmit = (values, e) => {
    if (loading) return;
    setLoading(true);

    const formData = new FormData()
    formData.append("location", values.location)
    formData.append("lang", lang)
    formData.append("lat", lat)
    formData.append("lng", lng)
    formData.append("action", 'store_search')
    formData.append("max_results", 25)
    formData.append("search_radius", values.radius)
    if (values.filter) {
      formData.append("filter", values.filter)
    }

    // setLoading(true)

    return fetch(process.env.wordPressUrl + "/wp-admin/admin-ajax.php", {
      method: "post",
      body: formData,
    }).then(response => response.json())
      .then((data) => {
        if (data) {
          Promise.all(
            data.map(async (maker) => {
              const address = 'ul. ' + maker.address + ' ' + maker.address2 + ', ' + maker.city + ' ' + maker.zip
              await Geocode.fromAddress(address)
                .then(
                  (response) => {
                    let position = response.results[0].geometry.location;
                    maker.lat = position.lat;
                    maker.lng = position.lng;
                  },
                  (error) => {
                    console.error(error);
                  }
                );
            })
          ).then(() => {
            setStoresList(data)
            setLoading(false)
          })
        }
        else {
          setStoresList([])
        }
      })
  }



  const changeSelected = ((type, value) => {
    // console.log(type, value)
    let tmp = [];
    if (type === 'filter') {
      storesCategoryList.forEach(element => {
        element.selected = false;
        if (+value === element.termTaxonomyId) {
          element.selected = true;
        }
        tmp.push({
          name: element.name,
          selected: element.selected,
          termTaxonomyId: element.termTaxonomyId,
        })
      });
      setStoresCategoryList(tmp);
    }
    if (type === 'radius') {
      radius.forEach(element => {
        element.selected = false;
        if (+value === element.value) {
          element.selected = true;
        }
        tmp.push({
          name: element.name,
          selected: element.selected,
          value: element.value,
        })
      });
      setRadiusList(tmp);
    }
  })

  const onPlacesChanged = () => {
    const data = searchBox.getPlaces()[0].geometry.location;
    setLat(data.lat())
    setLng(data.lng());
  }
  const onSBLoad = ref => {
    setSearchBox(ref);
  };


  return (
    <form className="shopMapFilters form" onSubmit={handleSubmit(onSubmit)}>
      <div className="shopMapFilters__wrapper">

        <div className="form__input input--location">
          <label className="form__label" htmlFor="location">
            {strings.yourLocation}
            <span className="form__label__required">*</span>
          </label>
          <StandaloneSearchBox
            onPlacesChanged={onPlacesChanged}
            onLoad={onSBLoad}
          >
            <input
              placeholder={strings.yourLocationPlaceholder}
              type="text"
              id="location"
              {...register("location", {
                required: true,
              })}
            />
          </StandaloneSearchBox>
        </div>
        <div className="form__select input--radius">
          <label className="form__label" htmlFor="radius">
            {strings.searchRadius}
          </label>
          <select id="radius"
            {...register("radius", {
              required: true,
              onChange: e => changeSelected('radius', e.target.value),
            })}
          >
            {radiusList.map((item, index) => (
              item.selected ? (
                <option key={'radius_' + index} value={item.value} selected>
                  {item.name}
                </option>
              ) : (
                <option key={'radius_' + index} value={item.value}>
                  {item.name}
                </option>
              )
            ))}
          </select>
        </div>
        <div className="form__select input--category">
          <label className="form__label" htmlFor="filter">
            {strings.category}
          </label>
          <select id="filter"
            {...register("filter", {
              onChange: e => changeSelected('filter', e.target.value),
            })}
          >
            <option key={'filter_' + 1002} ></option>
            {storesCategoryList.map((item, index) => (
              item.selected ? (
                <option key={'filter_' + index} value={item.termTaxonomyId} defaultValue>
                  {item.name}
                </option>
              ) : (
                <option key={'filter_' + index} value={item.termTaxonomyId}>
                  {item.name}
                </option>
              )
            ))}
          </select>
        </div>
        <div className="form__button">
          <button type="submit">{strings.search}</button>
        </div>
      </div>
    </form >
  )
}
export default ShopMapFilters
