import React, { useState } from "react"
import { LoadScript } from '@react-google-maps/api';
import ShopMapFilters from './shopMapFilters';
import ShopMapGoogle from "./shopMapGoogle";
import ShopMapItems from './ShopMapItems';
import "./style.scss"

const libs = ['places'];

const ShopMap = ({ lang, storesCategory, stores, strings }) => {

  const [sendingPass, setsendingPass] = useState(false)
  const [loading, setLoading] = useState(true)
  const [storesList, setStoresList] = useState([])
  const [activeItem, setActiveItem] = useState(0)

  // console.log(storesList);
  // useEffect(() => {
  //   setStoresList(stores);
  // }, [stores])

  return (
    <section className="shopMap">
      <LoadScript
        googleMapsApiKey={`${process.env.googleMapsApiKey}`}
        libraries={libs}
      >
        <ShopMapFilters strings={strings} setStoresList={setStoresList} storesCategory={storesCategory} lang={lang} sendingPass={sendingPass} setsendingPass={setsendingPass} loading={loading} setLoading={setLoading} />
        <div className="shopMap__wrapper">
          <ShopMapItems lang={lang} loading={loading} stores={storesList} activeItem={activeItem} setActiveItem={setActiveItem} />
          <ShopMapGoogle loading={loading} stores={storesList} activeItem={activeItem} setActiveItem={setActiveItem} />
        </div>
      </LoadScript>
    </section>
  )
}
export default ShopMap
