import React from 'react';
import Spin from '../spin/Spin';


const ShopMapItems = ({ stores, loading, setActiveItem, activeItem }) => {
  return (
    <div className="shopMapItems">
      {loading ? (
        <div className="shopMapItems__spin">
          <Spin />
        </div>
      ) : (
        stores.map((store, index) => (
          <a key={'storeItems_' + index} href="#" onClick={(e) => { e.preventDefault(); setActiveItem(index) }} className={`shopMapItems__item ${index === activeItem ? 'shopMapItems__item--active' : ''}`}>
            <span className="shopMapItems__item__name"><span dangerouslySetInnerHTML={{ __html: store.store }}></span></span>
            <span className="shopMapItems__item__address">{store.address} {store.address2}<br />{store.city} {store.zip} {store.country}</span>
            <span className="shopMapItems__item__distance">{store.distance} km</span>
          </a>
        ))
      )}
    </div>
  )
}
export default ShopMapItems
