import React, { useState } from "react";
import { isEmpty } from 'lodash';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { getOgImage } from "../../utils/functions";
import ShopMap from "../../components/shop/shopMap";
import { WPViewContent } from "../../utils/WPpixel";

// import "./style.scss"

// ${process.env.wordPressUrl}


const ShopPage = (props) => {
  const {
    pageContext: {
      title, seo, uri, content, storesCategory, stores
    }
  } = props;
  
  useState(()=>{
    WPViewContent('View');
  },[]) 

  const { code: locale } = props.pageContext.wpmlTranslated.current[0];
  const { node: translated } = props.pageContext.wpmlTranslated
  const lang = locale.substring(0, 2);
  const shop = props.pageContext.acfShop;

  return (
    <Layout lang={lang} page="shop" translated={translated}>
      {
        !isEmpty(props.pageContext) ? (
          <>
            <SEO
              title={title}
translated={translated}
              seoData={seo}
              uri={uri}
              lang={lang}
              page={"shop"}
              // header={ siteTitle: 'Gatsby WooCommerce Theme' }}
              openGraphImage={getOgImage(seo)}
            />
            <div className="shop page">
              <section className="description">
                <div className="container">
                  <div className="page__hero">
                    <h1 className="heading heading--xl">
                      {title}
                    </h1>
                    <div className="content" dangerouslySetInnerHTML={{ __html: content }}></div>
                  </div>
                  <ShopMap lang={lang} strings={shop} storesCategory={storesCategory} stores={stores} />
                </div>
              </section>

            </div>
          </>
        ) : (
          <div>Something went wrong</div>
        )
      }
    </Layout>
  )
};
export default ShopPage;

